.bg-blur {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #c00a28;
  z-index: 50;
  transition: all 0.25s;
}

.loading {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 60;
}

.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 40;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  z-index: 30;
  border: 1px solid white;
  left: 0;

  &:first-child {
    background-color: #ffef62;
    animation-delay: 0.5s;
  }
  &:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  &:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  &:nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  &:nth-child(6) {
    background-color: #f0a310;
    animation-delay: 0s;
  }
}

.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  color: white;
  z-index: 20;

  &:after {
    content: "Loading";
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
