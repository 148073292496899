a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
button,
canvas,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
nav,
ol,
output,
p,
pre,
q,
s,
samp,
section,
select,
sub,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
u,
ul,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  line-height: 1.5;
  border-radius: 0;
  outline: 0;
  color: inherit;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
  background-color: transparent;
}
blockquote,
q {
  quotes: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
input,
progress,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
svg {
  width: 1.9em;
  height: 1.9em;
  fill: currentColor;
}
