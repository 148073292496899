.simpleArrow {
  position: absolute;
  top: 40%;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
}
.next {
  right: 0;
}
