@font-face {
  font-family: Samim;
  src: url(./assets/font/Samim-FD.woff);
}

* {
  text-align: initial;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
}

body[dir="ltr"] {
  font-family: Arial, Helvetica, sans-serif;
}

body[dir="rtl"] {
  font-family: Samim;
}

.mainMargin {
  margin: 1rem 0;
}

@media only screen and (max-width: 1270px) {
  .mainMargin {
    margin: 1rem 1rem 1rem 0;
  }
}

@media only screen and (max-width: 599px) {
  .mainMargin {
    margin: 1rem 0;
  }
}

h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -0.01562em;
  margin-bottom: 5px;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -0.00833em;
  margin-bottom: 5px;
}

h3 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.00735em;
  margin-bottom: 5px;
}

h5 {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0em;
  margin-bottom: 5px;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-bottom: 5px;
}

hr {
  box-sizing: border-box;
  width: 100%;
  height: 0;
  overflow: visible;
  border: 1px solid #eee;
}

a:hover {
  text-decoration: none;
}

.fullHeight {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.fullHeight>main {
  position: relative;
  flex-grow: 1;
}

iframe.component-frame {
  z-index: 0 !important;
}

/* toast start*/
.Toastify__toast-container {
  width: auto !important;
  min-width: 320px !important;
}

.Toastify__toast {
  border-radius: 5px !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  font-family: inherit !important;
}

.Toastify__toast-body {
  padding: 0 10px !important;
}

.Toastify__toast--info::before,
.Toastify__toast--success::before,
.Toastify__toast--warning::before,
.Toastify__toast--error::before,
.Toastify__toast--default::before {
  margin: auto 0 !important;
  height: 22px !important;
  width: 22px !important;
}

.Toastify__toast--info::before {
  content: url(./assets/img/info-white-18dp.svg) !important;
}

.Toastify__toast--success::before {
  content: url(./assets/img/check_circle_outline-white-18dp.svg) !important;
}

.Toastify__toast--warning::before {
  content: url(./assets/img/report_problem-black-18dp.svg) !important;
}

.Toastify__toast--error::before {
  content: url(./assets/img/error_outline-white-18dp.svg) !important;
}

.Toastify__toast--default::before {
  content: url(./assets/img/chat_bubble_outline-black-18dp.svg) !important;
}

.Toastify__toast--warning {
  color: #000 !important;
}

.Toastify__toast--default {
  color: #555 !important;
}

.Toastify__toast button {
  opacity: 0.9 !important;
}

.Toastify__toast--warning button {
  color: #000 !important;
}

/* toast end*/

button:focus {
  outline: none;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

ul[class*="MuiMenu-list"] {
  color: #000;
  background-color: #eee;
}

div[class*="react-datepicker"] {
  font-family: inherit !important;
}

.multiSelectDropDownItem.Mui-selected::before,
.optionDropDownItem.Mui-selected::before {
  content: "\2713";
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 600;
}

li.multiSelectDropDownItem,
li.optionDropDownItem {
  border-bottom: 1px solid #555;
  white-space: normal;
}

/* .leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
} */