.circleBtn {
  width: 1.5rem !important;
  height: 1.5rem !important;
  background-color: white !important;
  border-radius: 100% !important;
  // border: 1px solid #686868 !important;
  margin-right: 1rem !important;
  color: white !important;
  content: " " !important;
}

.slick-active {
  button {
    background-color: #cfcfcf !important;
  }
}
.slick-dots li button:before {
  content: " " !important;
}
