.profileTitle,
.profileDescribe {
  text-align: center;
  color: #004064;
}

.profileAddresses {
  a {
    color: rgb(33, 37, 41);
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  }

  h5,
  p {
    color: #004064;
  }
  .addresses{
    .addressCard{
      margin: 1rem 1rem 1rem 0;
    }
    .cardHeader{
      display: flex;
      justify-content: space-between;
     margin: 0.5rem 0;
      font-size: 1.4rem;
    }
  }
}
