.profile {
  display: flex;
  // position: relative;
  min-height: 100%;
  background-color: #eeeeee;

  .profileMenu {
    position: relative;
  }
  .profileMain {
    flex-grow: 1;
    min-height: 76vh;
    padding: 1rem;
  }
  .box {
    padding: 1rem;
    height: 100%;
  }

  h6 {
    font-weight: 600;
  }
}
